<template>
  <div>
    <!-- 出品企业 -->
    <div class="fromenterprise">
      <div class="baseinfo">餐饮企业</div>
      <div class="name">新泉鸡宴</div>
      <!-- <img
        style="width: 264px; display: block; margin: 0 auto; margin-top: 11px"
        src="../../assets/images/project1/tag.png"
        alt=""
      /> -->
      
      <div class="intruduceInfo">
        新泉鸡宴是海南新泉文昌鸡有限公司旗下文昌鸡体验旗舰店，“天赐良鸡 非遗好味”，新泉鸡宴采用官方认证授权文昌鸡地理标志养殖场出品的文昌鸡，是以绿色、健康、美味为理念，经过严格的现代化养殖和传统笼养文昌鸡喂养套餐（大米、地瓜、米糠、花生饼、椰子饼）相结合养殖出来的文昌鸡中的“战斗鸡”，以白切鸡为主打产品，姜丝炒文昌鸡、椰子油煎文昌鸡、脆皮烧文昌鸡、原味文昌鸡、椰奶文昌鸡、木瓜花胶椰子鸡、猪肚文昌鸡、海鲜砂锅焖文昌鸡、甲鱼海参焖文昌鸡，拾种做法，满足您对鸡的一切幻想，给您最全的文昌鸡体验，让您真切体会食鸡知味的美食盛宴。
      </div>
    </div>
    <!-- 企业资质 -->
    <div
      class="fromenterprise2"
      style="padding-bottom: 5px"
      v-if="business.itemList.length != 0"
    >
      <div class="baseinfo">企业资质</div>
      <div style="margin-bottom: 16px">
        <div class="first" style="display: flex">
            <img
              class="firstImg"
              src="../../assets/images/project4/project4BusinessImg.png"
              alt=""
              @click="reportClick('project4BusinessImg')"
            />
        </div>
        <div
          style="
            text-align: center;
            margin-top: -8px;
            z-index: 1;
            position: relative;
          "
        >
          <img
            style="width: calc(100% - 32px); height: 43px"
            src="https://asset.fsytss.com/trace/wcj/icon_business_f1_1.png"
            alt=""
          />
          <div
            style="
              margin-top: -27px;
              font-weight: bold;
              font-size: 15px;
              color: #b54230;
              line-height: 20px;
            "
          >
            食品经营许可证
          </div>
      
        </div>
      </div>
      <div style="margin-bottom: 16px">
        <div class="first" style="display: flex">
          <img
              class="secondImg"
              src="../../assets/images/project4/project4Business2Img.png"
              alt=""
              @click="reportClick('project4Business2Img')"
            />
        </div>
        <div
          style="
            text-align: center;
            margin-top: -8px;
            z-index: 1;
            position: relative;
          "
        >
        <img
              style="width: calc(100% - 32px); height: 23px"
              src="https://asset.fsytss.com/trace/wcj/icon_business_f23_2.png"
              alt=""
            />
        </div>
      </div>
    </div>
    <!-- 产地定位 -->
    <div class="fromenterprise2" v-if="business.latitude">
      <div class="baseinfo">产地定位</div>
      <div class="placeAddress">
        <div class="address" id="businessMap"></div>
        <div class="addressInfo">地址：海南省文昌市文城镇城西路56号一,二楼</div>
      </div>
    </div>
    <!-- 企业区块链 -->
    <div class="fromenterprise2" style="padding-top: 0">
      <div class="baseinfo">企业区块链</div>
      <div class="area" style="background: #fff; padding: 0 9px">
        <div
          style="
            display: flex;
            justify-content: flex-end;

            margin-right: 7px;
          "
        >
          <div
            style="color: #b44130; margin-top: 8px; font-size: 13px"
            @click="imagePreview(url)"
          >
            点击放大 >
          </div>
        </div>
        <div class="areaimg" ref="canvasImg"  >
          <div class="areaimgText" >
            <div>海南省文昌市畜牧兽医服务中心</div>
            <div class="only">{{ supplierContractId }}</div>
            <div>{{ businessFattenPushPopTime }}</div>

          </div>
          <!-- <img ref="canvasImg"  style="width: 100%; height: 100%" :src="url" alt="" /> -->
        </div>
      </div>
    </div>
    <!-- 底部技术支持 -->
    <div class="bottom">一天膳事提供技术服务</div>
      <!-- 加载loading -->
      <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vPlayBack from "v-playback";
import moment from "moment";
import { Image as VanImage, ImagePreview ,Loading} from "vant";
import html2canvas from "html2canvas";
Vue.use(VanImage).use(ImagePreview).use(Loading);
Vue.use(vPlayBack);

export default {
  name: "HomeBusinessInfoComponent",
  props: ["business", "url", "supplierName",'supplierContractId','businessFattenPushPopTime'],
  data() {
    return {
      loadingAll:false,
      data: {
        title: "出品企业",
        businessMap: null,
        map: null,
        businessMarkerLayer: null,
        introductionArr:[],

      },
    };
  },
  mounted() {
   this.data.introductionArr = this.business.introduction.split('\n').filter(paragraph => paragraph.trim() !== '');
    this.initMap();
    this.formattList();
  },
  methods: {
    reportClick(arr){
      ImagePreview([
      require('@/assets/images/project4/'+arr+'.png')
]);
    },
    async createImage() {
      try {
        const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        this.imgUrl = image;
        ImagePreview([image]);
        this.loadingAll=false
      } catch (e) {
        this.loadingAll=false
        throw new Error(e);
       
      }
    },
    imagePreview(url) {
      this.loadingAll=true
      this.createImage();
      
    },
    initMap() {
      if (!this.business.latitude) return;
      let latitude = Number(19.75);
      let longitude = Number(110.59);//经度

      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.businessMap = new TMap.Map("businessMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });

      //创建并初始化MultiMarker
      this.businessMarkerLayer = new TMap.MultiMarker({
        map: this.businessMap, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "111", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
    formattList() {
      if (!this.business.itemList || this.business.itemList == "") return;
      let list = this.business.itemList;
      const groupedData = list.reduce((acc, item) => {
        const { lineNumber } = item;
        if (!acc[lineNumber]) {
          acc[lineNumber] = [];
        }
        acc[lineNumber].push(item);
        return acc;
      }, {});
      const result = Object.values(groupedData);
      this.business.itemList = result;
    },
  },
};
</script>

<style lang="less" scoped>
.fromenterprise {
  width: 319px;
  margin: 0 auto;
  margin-top: 27px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  padding-top: 29px;
  padding-bottom: 16px;
}
.fromenterprise2 {
  width: 319px;
  margin: 0 auto;
  margin-top: 27px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  padding-top: 29px;
  background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
}
.baseinfo {
  position: absolute;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(-135deg, rgb(192, 73, 51), rgb(226, 187, 125));
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -11px;
}
// 企业荣誉
.first {
  width: calc(100% - 32px - 32px);
  position: relative;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  .firstImg {
    margin: 0 auto;
    max-width: 100%;
    height: 45vw;
    display: block;
    object-fit: contain;
  }
  .secondImg {
    max-width: 100%;
    height: 100%;
    max-height: 35vw;
    display: block;
    object-fit: contain;
    // max-width: calc(50% - 4px);
    // height: 35vw;
    // display: block;
    // object-fit: contain;

    
  }
}

.name {
  padding: 0 16px;
  position: relative;
  font-weight: 700;
  font-size: 21px;
  color: #231815;
  line-height: 20px;
  text-align: center;
}
.intruduceInfo {
  padding: 12px 21px;
  padding-bottom: 0;
  font-size: 12px;
  color: #231815;
  line-height: 21px;
  text-indent: 16px;
  .pargath:not(:last-child) {
    margin-bottom: 12px;
}
}
.placeAddress {
  position: relative;
  margin-top: -11px;
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
}
.address {
  width: 287px;
  height: 193px;
  margin: 0 auto;
  margin-top: 16px;
  //   border: 1px solid #000;
}
.addressInfo {
  font-size: 13px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 16px;
  padding: 0 16px;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.businessTagContainer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  .businessTag {
    display: flex;
    justify-content: center;
    // margin-right: 6px;
  }
}

.area {
  border-radius: 8px;
  padding-bottom: 8px!important;
}
.areaimg {
  margin: 9px auto;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // width: 100%;
  // height: 210px;
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_blue_business.png") no-repeat
  //   center / cover;
    background: url("../../assets/images/project1/areaBlueBusiness.png") no-repeat
    center / cover;
  margin-bottom: 8px;
}
.areaimgText{
  padding-top: 18.2vw;
  padding-left: 24vw;
  padding-right: 7vw;
  text-align: right;
  div{
    font-size: 12px;
    word-break: break-all; 
    color: #231815;
// line-height: 15px;
  }
  .only{
    margin: 1.5vw 0 1.2vw 0;
  }
}
</style>